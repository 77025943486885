import React, { useState } from 'react'

const Header = () => {

  const [primary,setPrimary] = useState('#94524A')

  const changePrimary = (e) => {
    setPrimary(e.target.value)
  }

  const [secondary,setSecondary] = useState('#FFBA08')

  const changeSeconday = (e) => {
    setSecondary(e.target.value)
  }

  const [tertiary,setTertiary] = useState('#264027')

  const changeTertiary = (e) => {
    setTertiary(e.target.value)
  }

  const textColorPrimary = {
    color: primary
  }

  const textColorTertiary = {
    color: tertiary
  }

  const divColor = {
    backgroundColor: secondary
  }

  const otherDivColor = {
    backgroundColor: tertiary
  }


  return ( 
    <div>
      <div style={divColor} className="pt-2 pb-5 px-60">
        <p className="text-title text-center font-bold font-primary" style={textColorPrimary}>Simply Khanna</p>
        <p className="text-2xl text-center font-bold font-secondary mt-2" style={textColorTertiary}>some cool sounding tagline</p>
      </div>
      <div style={otherDivColor} className="pt-5 flex space-x-2 h-screen text-white">
        <div>
          <p>Primary</p>
          <input value={primary} onChange={changePrimary} type="text" className="formInput text-black" />
        </div>
        <div>
          <p>Secondary</p>
          <input value={secondary} onChange={changeSeconday} type="text" className="formInput text-black" />
        </div>
        <div>
          <p>Tertiary</p>
          <input value={tertiary} onChange={changeTertiary} type="text" className="formInput text-black" />
        </div>
      </div>
    </div>
   );
}
 
export default Header;